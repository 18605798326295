import { useState } from 'react';

import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import AccordionSummary from '@mui/material/AccordionSummary';
import styled from '@mui/system/styled';

import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} sx={{
    }} square {...props}></MuiAccordion>
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    
  }));
  
  
  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  }));

const FooterAccordion = ({accordionType, classes, title, children, type, open = false}) => {   
    const [accordionExpanded, setAccordionExpanded] = useState(open);
    const theme = useTheme();
    const handleAccordion = (panel) => (event, newExpanded) => {
        setAccordionExpanded(newExpanded ? panel : false);
    };

    return (
        <Accordion 
            sx={{
                backgroundColor: ['hiring', 'basket'].some(el => accordionType.includes(el)) ? theme.palette.primary.main : 'unset',
                color: ['hiring', 'basket'].some(el => accordionType.includes(el)) ? '#fff' : 'unset',
            }} expanded={accordionExpanded === 'company'} onChange={handleAccordion('company')}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{color: ['hiring', 'basket'].some(el => accordionType.includes(el)) ? '#fff' : accordionExpanded != false ? theme.palette.primary.main : 'unset'}} />}
                onChange = {(e,expanded) => {
                  console.log(expanded)
                }}
                aria-controls={`${accordionType}-content`}
                id={`${accordionType}-header`}
            >
                <Typography component="span" className={classes + " uppercase"}>{title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {children}
            </AccordionDetails>
        </Accordion>
    )
}

export default FooterAccordion